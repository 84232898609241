@forward '../../../Sass/style';
@import 'animate.css';

.UsersPanel .iconsContainer
{
    padding-left: 23.5rem;
    padding-right: 23.5rem;
}
@media only screen and (max-width: 600px) {

    .UsersPanel .iconsContainer
    {
        padding-left: 0rem;
        padding-right: 0rem;
        
    }
}
@forward '../Sass/style';
// Default theme
@import '@splidejs/react-splide/css';
.HandN
{
     height:45rem;
     display: flex;
     justify-content: center;
     background-color:var(--SecBackground);
    flex-direction: column;
    justify-content: flex-start;
}
.Title
{
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}
.splide__slide {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}
img{
    height: 20rem;

}
.splide.is-initialized, .splide.is-rendered {
    visibility: visible;
    display: flex;
}

.MainCard
{   text-align: center;

  
}
.Card
{
 
    display: flex;
    background-color: rgb(222, 222, 222);
    background-image: url('../../Media/abstract.png');
    border-radius: 3rem;
    text-align: center;
    background-image: url('../../Media/abstract.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 3;
    transition: all 300ms ease-in-out;
}

.Card img
{
    padding : 1rem;
    transition: all 300ms ease-in-out;
}
.Card:hover img
{
    transform: translateY(-0.3rem);
}
.Card:hover
{
    background-color: gray;
}
.title
{
    padding: 0.2rem;
}
.color
{
    padding: 0.2rem;
    color: white;
}
.price{padding: 0.2rem;
color: white;}
.splide__arrow {
    background: none;
    opacity: 1;

}
.splide__arrow svg {
    fill: var(--Orange);
    width: 5rem;
    height: 5rem;
}
.splide__pagination__page 
{
    background-color: var(--LightOrange);
}
.splide__pagination__page.is-active {
    background-color: var(--Orange);
}
.splide__pagination {
    bottom: -3.5rem;
}
.splide {
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 600px) {
    .HandN{height: 52em;
    justify-content: center;}
    .Title
    {
        padding-top: 0rem;
    }
}

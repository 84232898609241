@forward '../Sass/style';
@import 'animate.css';

.Hero
{   padding-top: 5.3rem;
    width: 100%;
    height:46rem ;
    position: relative;
    overflow: hidden;
    display: grid;
}
.heroMobile{
    display: none;
}

.hoodieb
{
    z-index: 3;
    width: 24rem;
    height: auto;
    position: absolute;
    transform: translate(0rem, 0rem);
    transition: all 500ms ease-in-out;
}
.hoodieb:hover
{
    transform: translateY(-3rem);
}

.hoodiew
{
    z-index: 2;
    width: 22rem;
    height: auto;
    position: absolute;
    transform: translate(15rem, 3rem);
    transition: all 500ms ease-in-out;
    

    
}
.hoodiew:hover
{
    transform: translate(15rem, 2rem);
}
.text
{
width: 47rem;
 position: relative;
 height: auto;
 justify-self: end;


 
}
.textImage
{
 transition: all 500ms ease-in-out;
}
.textImage:hover
{
transform: translateY(-2rem);
}
.abstract{
    z-index: 1;
    width:44rem ;
    height: auto;
     position: absolute;
     transform: translateY(-1rem);

}
@media only screen and (max-width: 600px) {
    .Hero{display: flex;
    height: 52em;
        flex-direction: column;
        align-items: center;
}

    .hoodieb{
display: none;
    }
.hoodiew{
 display: none;
}
.text{
    order: 0;
    margin-bottom: 2rem;
    display: flex;
        justify-content: center;

}
.textImage
{
    width: 20rem;
    height: auto;
}
.heroMobile
{
    display: flex;
    width: fit-content;
    justify-content: center;
    height: 20rem;
    width: 100%;
}
.heroMobileImage
{
    width: 20rem;
    height: auto;
}
.abstract{
    display: none;
}
}
@forward '../Sass/style';
@import 'animate.css';
body
{
 color: var(--Orange);
}
.AdminNavbar
{   
    z-index: 5;
    width: 100%;
    display: flex;
    height: 5.2rem;
    background-color:var(--Background) ;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 700;
    position:fixed;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
    
}

.logo
{
    height: 5.2rem;
    transition: all 300ms ease-in-out;
}
.logo:hover
{
    transform: scale(1.2);
}

.navItem
{  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #ffffff 65%,
    var(--Orange) 35%);
    font-size: var(--Medium);
    background-size: 100% 200%;
  background-position: 0 -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s ease-in-out;
  border-bottom: solid 2px var(--Orange);
  
  
}
.navItem:before{
  content: '';
  background: #ffffff;
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 0;
  height: 5.2rem;
  transition: all 0.6s ease-in-out;
  
}
.navItem:hover {
 background-position: 0;
 border-bottom: solid 2px white;
}

.contactbtn
{   display: flex;
    height:3.6rem ;
    align-items: center;
    border: solid 2px var(--Orange);
    color: var(--Orange);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 3rem;
    width: 8.8rem;
    justify-content: space-around;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.contactbtn:hover
{
    background-color: var(--Orange);
    border-color: white;
    color: #ffffff;
   
    

}
.contactbtn:hover p
{
    
    animation: pulse;
    animation-duration: 0.5s;

}
.burgerMenu
{
    display: none;
}
.mobileMenu
{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    background-color: var(--Background);
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    color: var(--Orange);
    top: 0;

}
.mobileMenu ul{
    padding-inline-start: 0;
    padding-top: 1rem;
    
    
}
.mobileMenu .burgerMenu
{   position:fixed;
    right: 1rem;
}
.mobileMenu a
{   font-size: var(--Large);
    color: var(--Orange);
}


@media only screen and (max-width: 600px) {
    .AdminNavbar{
        top: 0;
        max-width: 100%;
        overflow: hidden;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }
    .navItem
    {
        display: none;
    }
    .contactbtn
    {
        display: none;
    }
    .burgerMenu
{
    display: block;
    width: 1.7rem;
    height: auto;
}
}
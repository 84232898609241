@forward '../Sass/style';
@import 'animate.css';

.Footer{
 height: 24rem;
    width: 100%;
    background-color: var(--Background);
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.logo
{
    height: 10rem;
}
.Box
{
    display: flex;
    flex-direction: column;
}
.left-Box
{
    margin-left: 3.6rem;
}
.copyrights
{
    color: white;
    font-size: var(--Small);
    text-align: center;
}
.right-Box
{
    text-align: right;
    margin-right: 3.6rem;
    color: var(--Orange);
    border-bottom: none;
}
.Footer .navItem
{
    border-bottom: none;
    font-weight: bolder;
}
.socialIcon
{
    color: white;
    padding: 0.3rem;
    margin:0.3rem;
    border: solid 3px rgba(255, 255, 255, 0);
    border-radius: 10rem;
    background-color: 0;
    transition: all 300ms ease-in-out;
}
.socialIcon:hover
{
     color: var(--Orange);
     border: solid 3px var(--Orange);
     background-color: white;
}
.socialIcons
{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 3rem;
}
a
{
    color: white;

}
.icons{margin-top: 1rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;}
.iconPlaceholder
{   
    align-self: center;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    transition: all 300ms ease-in-out;
}
.iconPlaceholder:hover
{
    color: var(--Orange);
}
.iconPlaceholder:hover .socialIcon
{
     color: var(--Orange);
     border: solid 3px var(--Orange);
     background-color: white;
}


.middle-Box
{
    text-align: center;
}
@media only screen and (max-width: 600px) 
{
    .Footer
    {   height: 36rem;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 3rem;
    }
    .left-Box
    {
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }
    .right-Box
    {
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }
    .copyrights
    {
        padding-bottom: 3rem;
    }
    
    
}
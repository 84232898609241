@forward '../Sass/style';
@import 'animate.css';
.CreateNewItem
{
    padding-top: 5.3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}
label h5
{
    padding-right: 2rem;
    width: 9rem;
}
a
{
    color: inherit;
}
.errorMessage
{
    padding-top: 3rem;
}

@forward '../../../Sass/style';
@import 'animate.css';
.Panel
{padding-top: 5.3rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;
background-color: var(--SecBackground);
}
.title
{
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.section
{
    padding-bottom: 1.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
justify-content:center; 
margin-left: 5rem;
margin-right: 5rem;
max-width: 100%;


}


.iconsContainer
{   margin-top: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 0px;
    background-color: var(--Background);
    padding:5rem;
    border-radius: 2rem;
}
@media only screen and (max-width: 600px) {
 
    .section
    {
        max-width: 100%;
        
    }
    .iconsContainer{
        max-width: 100%;
        flex-direction: column;
          padding-left: 0rem;
        padding-right: 0rem;
        
    }
}
.sectionName
{
    color: white;
    transition: all 300ms ease-in-out;
}
.sectionIcon
{
    margin: 2rem;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
    background-color: 0;
    border: solid 4px rgba(255, 255, 255, 0);
    border-radius: 10rem;
    padding-bottom: 1rem;
}


.section:hover .sectionIcon
{   padding: 2rem;
    color: white;
    background-color: var(--Orange);
    border: solid 4px white;
    margin: 0 0 1rem 0;

}


.section:hover .sectionName{
    color: var(--Orange);
}
@forward '../Sass/style';
@import 'animate.css';
.AdminLogin
{    height: 42rem;
    padding-top: 5.2rem;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--SecBackground);
}
.loginComponents
{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 5rem;

}
.error
{   
    height: 4rem;
    width: 12rem;
    padding-top: 1rem;
    text-align: center;
}

.textField
{
    border-color: var(--Orange);
}
label
{
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.AdminRegister h5
{
    padding-right: 2rem;
    width: 10rem;
}

.textField
{
    border-radius: 3rem;
    border: solid var(--Orange) 3px;
    background-color: rgba(255, 255, 255, 0);
    color: var(--Orange);
    text-align: center;
}
.textField::placeholder
{
    color: #c9893597;
}
h5
{

    transition: all 300ms ease-in-out;
}
label:hover h5
{
    transform: translateY(-0.2rem);
    scale: 1.2;
}
.textField:not(:placeholder-shown) h5
{transform: translateY(-0.2rem);
    scale: 1.2;

}
.RegisterBtn
{
    margin-top: 3rem;
    width: 13rem;
    background-color: var(--NavyBlue );
    padding: 0.7rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: solid var(--Orange) 3px;
    color: var(--Orange);
    border-radius: 3rem;
    transition: all 300ms ease-in-out;
}
.RegisterBtn:hover
{
    background-color: var(--SecBackground);
}


*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}


html, body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: var(--Background);
}


body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 0;
}

a,a:visited,a:active
{
    text-decoration: none;
    
}

#root, #__next {
  isolation: isolate;
}
.hidden
{
 opacity: 0;
}
.show
{
  opacity: 1;
}

.btn
{
    margin-top: 3rem;
    width: 13rem;
    background-color: var(--NavyBlue );
    padding: 0.7rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: solid var(--Orange) 3px;
    color: var(--Orange);
    border-radius: 3rem;
    transition: all 300ms ease-in-out;
}
.btn:hover
{
    background-color: var(--SecBackground);
}
select
{
  background-color: rgba(240, 248, 255, 0);
  border-color: var(--Orange);
  padding: 0.5rem;
  width: 12rem;
  border-radius: 3rem;
  color: var(--Orange);
}